<template>

	<div class="page_right_content">
		<div v-title data-title="多对多SaaS管理平台"></div>
		<nav class="page_head_nav">
			<p>产品管理</p>
			<i>/</i>
			<p>产品分类列表</p>
		</nav>

		<div class="head_search_wrap">

			<div class="search_one_line">
				<div class="left">
					<el-form label-width="120px" @submit.native.prevent>
						<el-form-item label="分类名称:">
							<el-input class="input_medi" v-model="keyword" @keyup.enter.native="searchFn" clearable
								placeholder="请输入产品名称"></el-input>
						</el-form-item>
					</el-form>
				</div>
				<div class="right">
					<p class="reset" @click="resetFn">重置</p>
					<p class="search" @click="searchFn">查询</p>
				</div>
			</div>

		</div>

		<div class="page_table">
			<div class="page_table_title">
				<div class="left">
					<p>分类列表</p>
				</div>
				<div class="right">
					<span class="span1" @click="addFn">新增</span>
				</div>
			</div>
			<el-table :data="listData" class="tb-edit" style="width: 100%" highlight-current-row>
				<el-table-column prop="cat_name" label="分类名称" align="left"></el-table-column>
				<el-table-column prop="alias" label="别称" align="center"></el-table-column>
				<el-table-column prop="sort" label="排序" align="center"></el-table-column>
				<el-table-column prop="create_time" label="创建时间" align="center"></el-table-column>
				<el-table-column prop="" label="状态" align="center">
					<template slot-scope="scope">
						<div class="status_color">
							<p v-if="scope.row.status == 1" class="p1">激活</p>
							<p v-else class="p2">冻结</p>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center" width="140px">
					<template slot-scope="scope">
						<div class="operation">
							<span @click="modifyFn(scope.row)">修改</span>
						</div>
					</template>
				</el-table-column>
			</el-table>

			<div class="block">
				<el-pagination @current-change="currentChange" :current-page="currentPage1" :total="total">
				</el-pagination>
			</div>

		</div>
		<el-backtop :bottom="130" :right="25"></el-backtop>
	</div>




</template>

<script>
	export default {
		name: 'goodsClassList',
		data() {
			return {
				listData: [], // 列表数据
				total: 0, //总页数
				currentPage1: 1, //当前页码
				keyword: '', // 关键字搜索
			}
		},
		components: {},
		created() {
			// 获取产品分类列表
			this.getGoodsList();
		},
		methods: {
			// 重置
			resetFn() {
				this.keyword = '';
				this.currentPage1 = 1;
				this.getGoodsList();
			},

			// 搜索
			searchFn() {
				this.currentPage1 = 1;
				this.getGoodsList();
			},

			// 获取产品分类列表
			getGoodsList() {
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}category/list`,
					data: {
						page: this.currentPage1,
						keyword: this.keyword,
					}
				}).then(res => {
					if (res.code == '0') {
						this.total = res.count;
						this.listData = res.data;
					}
				})
			},

			// 分页器函数
			currentChange(val) {
				this.currentPage1 = val;
				this.getGoodsList();
			},

			// 修改
			modifyFn(row) {
				this.$router.push({
					path: '/goodsClassEdit',
					query: {
						id: row.id,
					}
				})
			},

			// 新增
			addFn() {
				this.$router.push('/goodsClassEdit');
			},

		}
	}
</script>
<style scoped lang="less">
	/* 加长input */
	.input_long_plus {
		width: 500px;
	}

	/* 长input */
	.input_long {
		width: 400px;
	}

	/* 中等input */
	.input_medi {
		width: 200px;
	}

	/* mini input */
	.input_mini {
		width: 100px;
	}
</style>
